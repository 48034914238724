* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}

body {
    cursor: default;
    overflow-x: hidden;
    background-color: #fefefe;
    color: #111;
}

a {
    color: inherit;
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
}

::-webkit-scrollbar-track {
    background: #fefefe;
}

::-webkit-scrollbar-thumb {
    background: #7b90a3;
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
    background: #7b90a3da;
}

::selection {
    background: #7b90a3;
    color: #eee;
}
